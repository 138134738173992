import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import {HTMLContentParse} from "../components/Content";

export const ImpressumPageTemplate = ({
                                    title,
                                    subtitle,
                                    text,
                                    image
                                  }) => (
  <div className="Content">
    <div className="Article">
      <div className="featured-image" style={{
        backgroundImage: `url(${
          image && image.childImageSharp
            ? image.childImageSharp.fluid.src
            : image
          })`
      }}></div>
      <div className="entry-content">
        <h1 className="entry-title">{title}</h1>
        <div className="entry-lead">{subtitle}</div>
        <HTMLContentParse className="entry-body" content={text} />
      </div>
    </div>
  </div>
)

ImpressumPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.object
}

const ImpressumPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <ImpressumPageTemplate
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        text={frontmatter.text}
        image={frontmatter.image}
      />
    </Layout>
  )
}

ImpressumPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    }),
  }),
}

export default ImpressumPage

export const pageQuery = graphql`
  query ImpressumPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "impressum-page" } }) {
      frontmatter {
        title
        subtitle
        text
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
